import { Root, createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { Controller } from './external-controller/Controller';




export class FacecastWebRTC {

    readonly controller = new Controller();

    private _root?: Root;

    private _isMounted: boolean = false;

    get isMounted() {
        return this._isMounted
    };

    constructor(public parentId: string = 'root') {

    }

    render() {
        this._isMounted = true;
        this._root = createRoot(document.getElementById(this.parentId) as Element);
     if (!this._root) {
            return;
        }
        this._root.render(<App controller={this.controller} />);
    }

    unmount() {
        this._isMounted = false;
        try {
        this._root?.unmount()
        } catch(e) {}
    }
}

//@ts-ignore
window.FacecastWebRTC = FacecastWebRTC;