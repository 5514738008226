import { useMemo } from "react";
import { useConnection } from "./useConnection";
import { useAsync } from "./useAsync";
import { of } from "rxjs";

export const useAudioTrack = (props: { connection: ReturnType<typeof useConnection> }) => {
    const { connection } = props;
    const audio$ = useMemo(() => {
        if (!connection)
            return of(undefined);
        return connection.audioTrack$
    }, [connection])
       const audio = useAsync({
        observable: audio$,
       })
    return audio;
}