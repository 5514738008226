import styled from "@emotion/styled"
import {  RemoteParticipant, RemoteTrack } from "livekit-client"
import { useMemo } from "react";
import { interval } from "rxjs";
import { useAsync } from "../../../hooks/useAsync";



const RedBack = styled.div`
    background-color: red;
    position: absolute;
`
export const DebugWindow = (props: { participant?: RemoteParticipant | null, video?: RemoteTrack | null, audio?: RemoteTrack | null, videoEl?: HTMLVideoElement | null }) => {
    const { participant, video, audio, videoEl } = props;

    const pollingInterval$ = interval(1000);

    const pollingIncrementor = useAsync({
        observable: pollingInterval$,
    })

    const quality = useMemo(() => participant?.connectionQuality, [participant, pollingIncrementor])

    const videoBitrate = useMemo(() => video?.currentBitrate, [video, pollingIncrementor])


    const audioBitrate = useMemo(() => audio?.currentBitrate, [audio, pollingIncrementor])

    const videoWidth = useMemo(() => {
        return videoEl?.videoWidth
    }, [videoEl, pollingIncrementor])

    const videoHeight = useMemo(() => {
        return videoEl?.videoHeight
    }, [videoEl, pollingIncrementor])


    return <RedBack>
            ConnectionQuality: {quality}
            <br/>
            videoBitrate: {videoBitrate}
            <br />

            audioBitrate: {audioBitrate}
            <br />
            videoWidth: {videoWidth}
            <br />
            videoHeight: {videoHeight}
        </RedBack>


}