import { Subject } from "rxjs";
import { ViewerBeatLogger } from "../logger/Logger";

export enum IFrameApiCMDEnum {
  get = "get",
  play = "play",
  stop = "stop",
  mute = "mute",
  unmute = "unmute",
  setVolume = "setVolume",
  playVideo = "playVideo",
  stopVideo = "stopVideo",
  playAudio = "playAudio",
  stopAudio = "stopAudio",
}

interface IFrameMsg {
  data: string | object;
}

interface ICMD<T = any, W extends IFrameApiCMDEnum = any> {
  frame: string;
  exec: W;
  value: T;
}

type COMMAND = ICMD




const logger = ViewerBeatLogger.setContext("IFrameApi");

export class IFrameApi {
  private _out$ = new Subject<COMMAND>();

  private _in$ = new Subject<any>();

  private static _instance: IFrameApi;

  get out$() {
    return this._out$.asObservable();
  }

  set in(value: any) {
    this._in$.next(value);
  }

  private constructor() {
    window.addEventListener("message", (e) => {
      this.handleMessage(e);
    });
    logger.debug("IFrame API Loaded");
    this._in$.subscribe((e) => window.parent.postMessage(e, "*"));
  }

  static getInstance(): IFrameApi {
    return IFrameApi._instance ?? new IFrameApi();
  }

  private handleMessage(event: IFrameMsg) {
    let cmd: COMMAND;
    try {
      cmd =
        typeof event.data === "object" ? event.data : JSON.parse(event.data);
    } catch (e) {
      return;
    }
    this._out$.next(cmd);
  }
}
