import { memo, useEffect, useMemo, useRef,  } from "react";
import { useLocation } from "react-router-dom";
import { BehaviorSubject, fromEvent, of, pairwise, } from "rxjs";
import { Absolute } from "./components/Absolute";
import { useAsync } from "../../hooks/useAsync";
import { TurnOnSound, } from "./components/TunrOnSoundButton";
import { VideoContainer } from "./components/VideoContainer";
import { IFrameApi, IFrameApiCMDEnum } from "../../iframe-api/iframe-api";
import { DebugWindow } from "./components/DebugWindow";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { ViewerBeatLogger } from "../../logger/Logger";
import { useConnection } from "../../hooks/useConnection";
import { useVideoTrack } from "../../hooks/useVideoTrack";
import { useAudioTrack } from "../../hooks/useAudioTrack";
import { useParticipant } from "../../hooks/useParticipant";
import { useGenerateToken } from "../../hooks/useGenerateToken";


export const ShowOneParticipant = memo(() => {
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location]);
    const url = useMemo(() => query.get('url') || 'wss://livekit.dev.facecast.net', [query]);
    const room = useMemo(() => query.get('room') || 'facecast-test', [query]);
    const audioAutoplay = useMemo(() => (query.get('audioAutoplay') === 'true' || !!Number(query.get('audioAutoplay'))) ?? true, [query]);

    const debug = useMemo(() => Boolean(query.get('debug')), [query]);

    const token = useGenerateToken({room})
    const publisher = useMemo(() => query.get('publisher') || 'streamer', [query])
    const videoContainerRef = useRef<HTMLDivElement>(null);

    const videoRef$ = useMemo(() => new BehaviorSubject<HTMLVideoElement | null>(null), []);
    const audioRef$ = useMemo(() => new BehaviorSubject<HTMLAudioElement | null>(null), []);
    const forceUpdate = useForceUpdate();

    const logger = useMemo(() => ViewerBeatLogger.setContext('ShowOneParticipant', room), [room])

    const iframeApi = useMemo(() => {
        return IFrameApi.getInstance()
    }, [])


    useEffect(() => {
        if (!logger) {
            return;
        }
        logger.debug(`Logger initialized. URL pathname ${location.pathname}. URL params ${location.search}`)
    }, [logger])

    useEffect(() => {
        logger.debug(`Force update emmited`)
    }, [ logger])


    const connection = useConnection({ url, token, publisher, room })

    const connectionStatus$ = useMemo(() => {
        if (!connection)
            return of('pending');
        return connection.status$
    }, [connection])

    const connectionStatus = useAsync({ observable: connectionStatus$ })

    useEffect(() => {
        logger.debug(`Connection status ${connectionStatus}`)
    }, [connectionStatus, logger])

    const video = useVideoTrack({connection})
    const audio = useAudioTrack({connection})
    const participant = useParticipant({connection})



    useEffect(() => {
        if (!audio ) {
            return;
        }
        logger.debug(`Have got new audiotrack. Attaching to the page. mediaStreamID: ${audio.mediaStreamID}`)
        audioRef$.next(audio.attach())
    }, [audio, audioRef$, logger])

    useEffect(() => {
        if (!video) {
            return;
        }
        logger.debug(`Have got new videotrack. Attaching to the page. mediaStreamID: ${video.mediaStreamID}`)
        videoRef$.next(video.attach() as HTMLVideoElement)
    }, [logger, video, videoRef$])

    const videoPair = useMemo(() => videoRef$.pipe(pairwise()), [videoRef$])
    const audioPair = useMemo(() => audioRef$.pipe(pairwise()), [audioRef$])
    const [prevVideoEl, videoEl] = useAsync({ observable: videoPair }) ?? [];
    const [prevAudioEl, audioEl] = useAsync({ observable: audioPair }) ?? [];

    useEffect(() => {
        if (prevAudioEl) {
            prevAudioEl.remove()
            logger.debug(`Previous audio has been removed from the page`)
        }
    }, [logger, prevAudioEl])

    useEffect(() => {
        if (prevVideoEl) {
            prevVideoEl.remove()
            logger.debug(`Previous video has been removed from the page`)
        }
    }, [logger, prevVideoEl])

    useEffect(() => {
        if (!(videoEl && videoContainerRef.current)) {
            return;
        }
        videoContainerRef.current?.appendChild(videoEl)
        logger.debug(`New videotrack has been appended`)
    }, [logger, videoEl])

    useEffect(() => {
        if ((!audioEl || !videoContainerRef.current)) {
            return;
        }
        audioEl.autoplay = audioAutoplay;
        videoContainerRef.current.appendChild(audioEl)
        logger.debug(`New audiotrack has been appended`)
    }, [audioEl, logger])


    const iframeAPISub = useMemo(() => {
        if (!iframeApi || iframeAPISub || !audioEl || !videoEl) {
            return;
        }
        logger.debug(`Creating a new subscription to IFrame API `)
        return iframeApi.out$.subscribe((cmd) => {
            switch (cmd.exec) {
                case IFrameApiCMDEnum.stopAudio:
                    audioEl?.pause()
                    break;
                case IFrameApiCMDEnum.playAudio:
                    audioEl?.play()
                    break;
                case IFrameApiCMDEnum.stopVideo:
                    videoEl?.pause()
                    break;
                case IFrameApiCMDEnum.playVideo:
                    videoEl?.play()
                    break;
                case IFrameApiCMDEnum.setVolume:
                    audioEl.volume = Number(cmd.value);
                    break;
                case IFrameApiCMDEnum.get:
                    iframeApi.in = ({
                        isPlaying: !videoEl?.paused,
                        duration: videoEl?.duration,
                        currentTime: videoEl?.currentTime,
                        videoTrack: video,
                    })
                    logger.debug(`IFrameApiCMDEnum.get `)
                    break;
                case IFrameApiCMDEnum.play:
                    videoEl?.play()
                    audioEl?.play()
                    logger.debug(`IFrameApiCMDEnum.play `)

                    break;
                case IFrameApiCMDEnum.stop:
                    videoEl?.pause()
                    audioEl?.pause()
                    logger.debug(`IFrameApiCMDEnum.stop `)
                    break;
                case IFrameApiCMDEnum.mute:
                    if (audioEl) {
                        audioEl.muted = true;
                    }
                    if (videoEl) {
                        videoEl.muted = true;
                    }

                    logger.debug(`IFrameApiCMDEnum.mute `)
                    break;
                case IFrameApiCMDEnum.unmute:
                    if (audioEl) {
                        audioEl.muted = false;
                    }
                    if (videoEl) {
                        videoEl.muted = false;
                    }
                    if (!videoEl.paused && audioEl.paused) {
                        audioEl.play();
                    }
                    logger.debug(`IFrameApiCMDEnum.unmute `)
                    break;
                default:
                    iframeApi.in = "Not Implimented"
            }
            forceUpdate();
        })
    }, [audioEl, iframeApi, logger, videoEl])

    const videoPlayEvent$ = useMemo(() => {
        if (!videoEl) {
            return of(null);
        }
        return fromEvent(videoEl, "play")
    }, [videoEl])

    const videoPlayEvent = useAsync({
        observable: videoPlayEvent$
    })

    const showTurnOnSound = useMemo(() => {
        logger.debug(`Checking if the turn on button has to be in view. Does the play event emmited: ${videoPlayEvent}`)
        return (audioEl?.muted) || (audioEl?.paused && !videoEl?.paused)
    }, [audioEl?.muted, audioEl?.paused, logger, videoEl?.paused, videoPlayEvent])

    if (connectionStatus !== 'connected') {
        return <div>{connectionStatus}</div>;
    }

    return <VideoContainer ref={videoContainerRef} id="VideoContainer-e8a8549c-9bcd-4010-88a0-6bb3796b2558"

    >
        <Absolute id="Absolute-2a458afe-e101-422b-b627-8db4bc67ded8" top={0} bottom={0} left={0} right={0} index={3}>
            {showTurnOnSound && <TurnOnSound onClick={() => { if (!audioEl) { return; } audioEl.play(); audioEl.muted = false; forceUpdate(); }} />}
        </Absolute>
        {debug  && (<DebugWindow participant={participant} video={video} audio={audio} videoEl={videoEl}  />)}
    </VideoContainer>
})