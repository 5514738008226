import { useMemo } from "react";
import { from, of } from "rxjs";
import { v4 } from "uuid";
import { getToken } from "../api/token";
import { useAsync } from "./useAsync";

export const useGenerateToken = (props: { room?: string, name?: string }) => {
    const { room, name = v4() } = props;
    const token$ = useMemo(() => {
        if (!room) {
            return of(undefined)
        }
        return from(getToken(room, name))
    }, [room])
    const token = useAsync({ observable: token$ });
    return token
}