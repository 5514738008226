import { RemoteTrack } from "livekit-client";
import { Subject } from "rxjs";
import { useConnection } from "../hooks/useConnection";

interface IRoomController {
    connectionOptions?: { url: string, token: string, publisher: string }
    onAudioTrack?: (v: RemoteTrack) => void,
    onVideoTrack?: (v: RemoteTrack) => void,
    onParticipant?: () => void,
}

export class RoomController implements IRoomController {

    public connectionOptions?: { url: string; token: string; publisher: string; room: string, adaptiveStream: boolean }

    public onAudioTrack?: ((v: RemoteTrack) => void) | undefined;
   public onVideoTrack?: ((v: RemoteTrack) => void) | undefined;
    public onParticipant?: (() => void) | undefined;
    public closeConnection$ = new Subject<void>()
    public connect$ = new Subject<void>()
    public connection?: ReturnType<typeof useConnection>;

    connect() {
        this.connect$.next()
    }

    closeConnection() {
        console.log("closeConnection")
        this.closeConnection$.next()
    }

    constructor() {}

}