import { useMemo } from "react";
import { useConnection } from "./useConnection";
import { useAsync } from "./useAsync";
import { of } from "rxjs";

export const useParticipant = (props: { connection: ReturnType<typeof useConnection> }) => {
    const { connection } = props;
    const participant$ = useMemo(() => {
        if (!connection)
            return of(undefined);
        return connection.participant$
    }, [connection])
       const participant = useAsync({
        observable: participant$,
       })
    return participant;
}