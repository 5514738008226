import { RELATIVE_PATH$ } from "./globals"

export const configuration = () => {
    const query = new URLSearchParams(window.location.search);
    const api = query.get("api")
    const TOKEN_GETTER_URL = `${api ?? RELATIVE_PATH$.value}/api`
    console.log(api, TOKEN_GETTER_URL)

    return ({
        TOKEN_GETTER_URL
    })
}