import { useState, useEffect } from 'react';

export function useDiffArrays<T>(props: { arr1?: T[], arr2: T[] }) {
    const [newElems, setNewElems] = useState<T[]>([]);
    const { arr1 = [], arr2 } = props;
    useEffect(() => {
        const difference = arr2.filter(num => !arr1.includes(num));
        setNewElems(difference);
    }, [arr1, arr2]);

    return newElems;
}
