import { PropsWithChildren, useState, useEffect } from "react";
import { Observable, isObservable, Subscription } from "rxjs";

type AsyncProps<T> = PropsWithChildren<{ observable: Observable<T> }>;

export function useAsync<T = any>({
    observable,
}: AsyncProps<T>): T | undefined {
    const [value, setValue] = useState<T | undefined>(undefined);
    useEffect(() => {
        let subscription: Subscription;
        if (!isObservable(observable)) {
            console.warn('is not observable')
            return;
        }
        subscription = observable.subscribe(setValue);
        return () => subscription.unsubscribe();
    }, [observable]);
    return value;
}