import { Subject, Subscription, bufferTime, filter, tap } from "rxjs";
import { v4 } from "uuid";

const elasticURL = "https://viewerbeat.facecast.net/api/viewerbeat";
const Buffer_Time = 5000;
const instance = "WEBRTC";

enum LevelEnum {
  LOG = "log",
  ERROR = "error",
  WARN = "warn",
  DEBUG = "debug",
}

interface Message {
  level: LevelEnum;
  instance: typeof instance;
  guid: string;
  session_id: string;
  user_agent: string;
  producer: string;
  message: string;
  event_code?: string;
}

export class ViewerBeatLogger {
  private static session_id = v4();
  private static loop: Subscription;
  public static setContext(value: string, event_code?: string) {
    return new ViewerBeatLogger(value, event_code);
  }
  private static async _sendToElastic(..._body: Message[]) {
    try {
      const res = await fetch(elasticURL, {
        method: "POST",
        body: JSON.stringify(_body),
      });
      res.text();
    } catch (e) {
      ViewerBeatLogger._msg(
        LevelEnum.ERROR,
        "_sendToElastic error",
        JSON.stringify(e)
      );
    }
  }
  private static readonly _msg$ = new Subject<
    [text: string, level?: LevelEnum]
  >();
  error(...any: string[]) {
    ViewerBeatLogger._msg$.next([`${any.join(" ")}`, LevelEnum.ERROR]);
  }
  warn(...any: string[]) {
    ViewerBeatLogger._msg$.next([`${any.join(" ")}`, LevelEnum.WARN]);
  }
  debug(...any: string[]) {
    ViewerBeatLogger._msg$.next([`${any.join(" ")}`, LevelEnum.DEBUG]);
  }
  private static _msg(level: LevelEnum, ...any: string[]) {
    ViewerBeatLogger._msg$.next([`${any.join(" ")}`, level]);
  }
  private constructor(
    private readonly _context: string = "[NO CONTEXT]",
    private event_code?: string
  ) {
    ViewerBeatLogger.loop =
      ViewerBeatLogger.loop ??
      ViewerBeatLogger._msg$
      .pipe(
        tap(s => console.trace(...s)),
        bufferTime(Buffer_Time),
          filter((s) => !!s.length)
        )
        .subscribe((strings) => {
          const messages: Message[] = strings.map((message) => {
            return {
              message: message[0],
              level: message[1] ?? LevelEnum.LOG,
              instance,
              guid: v4(),

              session_id: ViewerBeatLogger.session_id,
              user_agent: navigator.userAgent,
              producer: this._context,
              event_code: this.event_code,
            };
          });
          ViewerBeatLogger._sendToElastic(...messages);
        });
  }
}
