import { RemoteTrack, RemoteTrackPublication, RemoteParticipant, Track, LocalTrackPublication, LocalParticipant, Participant, Room, VideoPresets, RoomEvent, RoomOptions } from "livekit-client";
import { BehaviorSubject } from "rxjs";
import { ViewerBeatLogger } from "../logger/Logger";
import { useMemo } from "react";

interface ConnectOutput {
    audio$: BehaviorSubject<RemoteTrack | undefined>,
    video$: BehaviorSubject<RemoteTrack | undefined>,
    videoRemoteTrackPublication$: BehaviorSubject<RemoteTrackPublication | undefined>,
}
function handleTrackSubscribed(publisher: string, connectOutput: ConnectOutput) {
    return (
        track: RemoteTrack,
        _publication: RemoteTrackPublication,
        participant: RemoteParticipant,
    ) => {
        console.log('handleTrackSubscribed', participant);
        if (publisher !== participant.identity) {
            return;
        }
        if (track.kind === Track.Kind.Video) {
            // attach it to a new HTMLVideoElement or HTMLAudioElement
            connectOutput.video$.next(track)
            connectOutput.videoRemoteTrackPublication$.next(_publication);
            // videoContainer.appendChild(element);
        }
        if (track.kind === Track.Kind.Audio) {
            connectOutput.audio$.next(track)
        }
    }
}
function handleTrackUnsubscribed(
    track: RemoteTrack,
    _publication: RemoteTrackPublication,
    _participant: RemoteParticipant,
) {
    // remove tracks from all attached elements
    track.detach();
}

function handleLocalTrackUnpublished(track: LocalTrackPublication, _participant: LocalParticipant) {
    // when local tracks are ended, update UI to remove them from rendering
    (track as RemoteTrack & LocalTrackPublication).detach();
}

function handleActiveSpeakerChange(_speakers: Participant[]) {
    // show UI indicators when participant is speaking
}




const connect = (url: string, token: string, publisher: string, adaptiveStream: boolean = true) => {
    const audioTrack$ = new BehaviorSubject<RemoteTrack | undefined>(undefined)
    const videoTrack$ = new BehaviorSubject<RemoteTrack | undefined>(undefined)
    const participant$ = new BehaviorSubject<RemoteParticipant | undefined>(undefined)
    const videoRemoteTrackPublication$ = new BehaviorSubject<RemoteTrackPublication | undefined>(undefined)

    const query = new URLSearchParams(window.location.search);
    const logger = ViewerBeatLogger.setContext('LiveKit Connection', query.get('room') ?? undefined)
    const status$ = new BehaviorSubject<'pending' | 'connected' | 'disconnected'>('pending')

    const roomSettings: RoomOptions = {
        // automatically manage subscribed video quality
        adaptiveStream,

        // optimize publishing bandwidth and CPU for published tracks
        // dynacast: true,

        // default capture settings
        videoCaptureDefaults: {
            resolution: VideoPresets.h720.resolution,
        },
    };
    // creates a new room with options
    const room = new Room(roomSettings);

    room.participants.set = function(key: string, value: RemoteParticipant) {
        participant$.next(value)
        return Map.prototype.set.call(this, key, value);
    }

    // set up event listeners
    room
        .on(RoomEvent.Connected, () => { status$.next('connected') })
        .on(RoomEvent.TrackSubscribed, handleTrackSubscribed(publisher, {
            audio$: audioTrack$,
            video$: videoTrack$,
            videoRemoteTrackPublication$
        }))
        .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
        .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
        .on(RoomEvent.Disconnected, () => status$.next('disconnected'))
        .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished);

    // connect to room
    room.connect(url, token,).catch(e => logger.error(JSON.stringify(e)));
    return {
        ...room,
        audioTrack$: audioTrack$.asObservable(),
        videoTrack$: videoTrack$.asObservable(),
        status$: status$.asObservable(),
        participant$: participant$.asObservable(),
        videoRemoteTrackPublication$:
            videoRemoteTrackPublication$
    }
}

export const useConnection = (props: { url?: string, token?: string, publisher?: string, room?: string, adaptiveStream?: boolean }) => {
    const { url, token, publisher, room, adaptiveStream } = props;
    const logger = useMemo(() => ViewerBeatLogger.setContext('ShowOneParticipant', room), [room])

    const connection = useMemo(() => {
        if (!(url && token && publisher)) {
            return undefined;
        }
        logger.debug(`Connection initializing.`, url, token, publisher)
        return connect(url, token, publisher, adaptiveStream)
    }, [url, token, publisher]);
    return connection;
}