import { useState, useEffect } from "react";

export function useLoadCssOnce(cssUrl: string) {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const link = document.createElement("link");
    link.href = cssUrl;
    link.rel = "stylesheet";

    link.addEventListener("load", () => {
      setHasLoaded(true);
    });

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [cssUrl]);

  return hasLoaded;
}
