import { PreJoinPage } from "../pages/PreJoinPage/PreJoinPage";
import { RoomPage } from "../pages/RoomPage/RoomPage";
import { ShowOneParticipant } from "../pages/ShowOneParticipant/ShowOneParticipant";
import { Controller } from "./Controller"
import { RoomControllerPage } from "./RoomControllerPage";

export const ControllerRouter = (props: { controller: Controller }) => {
    const { controller } = props;
    const page = controller.page;
    switch (page) {
        case '/participant':
            return <ShowOneParticipant />
        case 'room':
            return <RoomPage />
        case '/room-controller':
            return <RoomControllerPage controller={controller} />
        default:
            return <PreJoinPage/>
    }
}