import { memo, useCallback, useEffect, useMemo } from "react"
import { useRecorder } from "../../hooks/useRecorder"
import { ControlButton, useParticipant, } from "@livekit/react-components"
import { Room } from "livekit-client"
import { usePrevious } from "../../hooks/usePrevious"
import { useDiffArrays } from "../../hooks/useDiffArrays"
import { useForceUpdate } from "../../hooks/useForceUpdate"
import { useAsync } from "../../hooks/useAsync"
import { interval, of } from "rxjs"

export const RecorderPanel = ((props: { room: Room }) => {
    const { room } = props;

    const update = useForceUpdate()


    const updateInterval$ = useMemo(() => interval(200)
        , [])

    const updateInterval = useAsync({ observable: updateInterval$ })

    const activeSpeakers = useMemo(() => {
        // console.log('asdloads')
        return [...room.participants].map(([_, v]) => v);
    }, [updateInterval])
    const additionalAudios = useMemo(() => {
        return activeSpeakers.map(speaker => {
            const tracks = speaker.getTracks();
            return tracks.map(track => track.audioTrack).map(track => track?.mediaStreamTrack);
        }).flat().filter(Boolean) as MediaStreamTrack[]
    }, [activeSpeakers])


    useEffect((() => {
        console.log("activeSpeakers", activeSpeakers.length)
    }), [activeSpeakers])

    const prevAudios = usePrevious(additionalAudios)

    const newAudios = useDiffArrays({ arr1: prevAudios, arr2: additionalAudios })



    const recorder = useRecorder({})
    const rec = useCallback((audio?: boolean, video?: boolean) => recorder && recorder.startRecording(additionalAudios, { audio, video }), [recorder])
    const stop = useCallback(() => recorder && recorder.stopRecording(), [recorder])
    const download = useCallback(() => recorder && recorder.downloadLastVideo(), [recorder])


    const isRecording$ = useMemo(() => {
        if (!recorder) {
            return of(undefined)
        }
        return recorder?.isRecording$
    }, [recorder]);
    const isDownloadAvaible$ = useMemo(() => {
        if (!recorder) {
            return of(undefined)
        }
        return recorder?.isDownloadAvaible$
    }, [recorder]);


    const isRecording = useAsync({ observable: isRecording$ });
    const isDownloadAvaible = useAsync({ observable: isDownloadAvaible$ });

    useEffect(() => {
        if (recorder?.isRecording) {
            console.log("newA", additionalAudios.map(a => a.id))
            recorder.addNewTracksToRecord(...additionalAudios)
        }
    }, [additionalAudios, additionalAudios.length])

    return <>
        {!isRecording && <ControlButton onClick={() => rec(true)} label="REC AUDIO"></ControlButton>}
        {!isRecording && <ControlButton onClick={() => rec(true, true)} label="REC WITH VIDEO"></ControlButton>}
        {isRecording && (<ControlButton onClick={stop} label="STOP"></ControlButton>)}
        {isDownloadAvaible && <ControlButton onClick={download} label="DOWNLOAD"></ControlButton>}
    </>
})