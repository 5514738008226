import styled from "@emotion/styled";

export const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    video {
        width: 100%;
        max-height: 100vh;
    }
    overflow: hidden;
`