import { useEffect } from "react";
import { useAudioTrack } from "../hooks/useAudioTrack";
import { useConnection } from "../hooks/useConnection"
import { useVideoTrack } from "../hooks/useVideoTrack";
import { Controller } from "./Controller"
import { useForceUpdate } from "../hooks/useForceUpdate";
import { useGenerateToken } from "../hooks/useGenerateToken";

export const RoomControllerPage = (props: {
    controller: Controller,
}) => {
    const {controller} = props
    const { connectionOptions, onAudioTrack, onVideoTrack } = controller.roomController;

    const { url,
        token,
        publisher,
        room,
        adaptiveStream,
    } = connectionOptions ?? {};

    const generatedToken = useGenerateToken({room})

    const connection = useConnection({
        url,
        token: token ?? generatedToken,
        publisher,
        room,
        adaptiveStream
    });
    controller.roomController.connection = connection;
    controller.roomController.connect$.subscribe(() => connection?.connect(url ?? '',token ?? generatedToken ?? ''))

    controller.roomController.closeConnection$.subscribe(() => connection?.disconnect())
    const forceUpdate = useForceUpdate();
    const audio = useAudioTrack({connection});
    const video = useVideoTrack({connection});
    useEffect(() => {
        if(audio)
        onAudioTrack?.(audio)
    }, [audio, forceUpdate]);
    useEffect(() => {
        if (video)
            onVideoTrack?.(video)
    }, [video, forceUpdate]);

    return <>{
        `${url}${token ?? generatedToken}${publisher}${room}`
    }</>
}