import { memo } from 'react'
import { IconsEnum } from './IconsEnum'

export const SvgSprite = memo(() => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>

            <symbol id={IconsEnum.TURN_SOUND_ON} viewBox="0 0 80 76" >
                <path d="M49.3 4.2a2.4 2.4 0 0 0-2.5.3L26.1 20.2H12.7A4.6 4.6 0 0 0 8 24.8v18.4c0 1.3.5 2.4 1.4 3.3.9.9 2 1.3 3.3 1.3h13.4l20.7 15.7a2.4 2.4 0 0 0 3.5-.6c.2-.4.4-.8.4-1.2V6.3c0-.4-.2-.8-.4-1.2-.2-.4-.6-.7-1-.9Zm10.8 20.6c-.6 0-1.2.2-1.6.6-.5.5-.7 1-.7 1.7v13.8c0 .6.2 1.2.7 1.7a2.4 2.4 0 0 0 3.3 0c.5-.5.7-1 .7-1.7V27.1c0-.6-.2-1.2-.7-1.7-.4-.4-1-.6-1.7-.6Zm9.5-4.6c-.6 0-1.2.2-1.6.6-.5.5-.7 1-.7 1.7v23c0 .7.2 1.2.7 1.7a2.4 2.4 0 0 0 3.3 0c.5-.5.7-1 .7-1.7v-23c0-.6-.2-1.2-.7-1.7-.4-.4-1-.6-1.7-.6Z" />
            </symbol>

        </svg>
    )
})
