import { BehaviorSubject } from "rxjs";
import { ControllerRouter } from "./ControlerRouter";
import { RoomController } from "./RoomController";
import * as globals from "../globals"

export class Controller {

    private page$ = new BehaviorSubject<string>(window.location.pathname);

    public useControllerRouter: boolean = false;;

    public globals = globals

    public roomController = new RoomController();

    set page(value: string) {
        this.page$.next(value)
    }

    get page(): string {
        return this.page$.value
    }

    reacreateRoomController() {
        this.roomController = new RoomController();
    }

    Router = () => ControllerRouter({controller: this})
    constructor() {
    }
}