
import styled from "@emotion/styled";
import { useCallback, } from "react";
import { IconsEnum } from "../../../svg/IconsEnum";
import { SvgIcon } from "../../../svg/SvgIcon";


export const TurnSoundOnStandartIcon = styled(SvgIcon)`
  display: block;
  width: 60px;
  height: 64px;
`
export const TurnSoundOnCompactIcon = styled(SvgIcon)`
  display: block;
  width: 24px;
  height: 22px;
`

export const TurnOnSoundStandart = (props: { onClick: () => void }) => {
    const { onClick } = props;
    const BluredWrapper = styled.div`
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 16px;

    margin: auto;
    width: 178px;
    height: 130px;
    padding: 20px 30px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`
    const Text = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-shadow: 0px 0px 1px rgba(28, 26, 36, 0.24), 0px 4px 8px rgba(95, 91, 113, 0.24);
  `
    const Icon = () => <TurnSoundOnStandartIcon fill="rgba(255, 255, 255, 0.8)" icon={IconsEnum.TURN_SOUND_ON}></TurnSoundOnStandartIcon>

    return <BluredWrapper id="TurnOnSound-c8341b6d-7fbc-48c8-b0ab-ec7095a9d299" onClick={onClick}>
        <Icon />
        <Text>Включить звук</Text>
    </BluredWrapper>
}
const TurnOnCompact = (props: { onClick: () => void }) => {
    const { onClick } = props;
    const BluredWrapper = styled.div`
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 12px;

    margin: auto;
    width: 180px;
    height:  46px;
    padding:12px 20px 12px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`
    const Text = styled.span`
   font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 14px;
    text-shadow: 0px 0px 1px rgba(28, 26, 36, 0.24), 0px 4px 8px rgba(95, 91, 113, 0.24);
  `
    const Icon = () => <TurnSoundOnCompactIcon fill="rgba(255, 255, 255, 0.8)" icon={IconsEnum.TURN_SOUND_ON}></TurnSoundOnCompactIcon>

    return <BluredWrapper id="TurnOnSound-67d246ad-c8a5-489a-b8dd-2648be74e933" onClick={onClick}>
        <Icon />
        <Text>Включить звук</Text>
    </BluredWrapper>
}

const TurnOnSuperCompact = (props: { onClick: () => void }) => {
    const { onClick } = props;

    const BluredWrapper = styled.div`
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(32px);
    border-radius: 8px;

    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
       padding:12px 20px 12px 20px;

    cursor: pointer;
`

    const Icon = () => <TurnSoundOnCompactIcon fill="rgba(255, 255, 255, 0.8)" icon={IconsEnum.TURN_SOUND_ON}></TurnSoundOnCompactIcon>

    return <BluredWrapper id="TurnOnSound-372132db-ce07-418e-85d4-0e93fe2057dd" onClick={onClick}>
        <Icon />
    </BluredWrapper>
}


export const TurnOnSound = (props: { style?: 'compact' | 'standart' | 'super-compact', onClick?: () => void }) => {
    const { style = 'super-compact', onClick = () => { } } = props;

    const onClickCallback = useCallback(() => {
        onClick();
    }, [onClick])
    let content = <></>;

        if (style === 'compact') {
            content = <TurnOnCompact onClick={onClickCallback} />
        } else if (style === 'standart') {
            content = <TurnOnSoundStandart onClick={onClickCallback} />
        } else if (style === 'super-compact') {
            content = <TurnOnSuperCompact onClick={onClickCallback} />
        }
    return content
}