import { IconsEnum } from "./IconsEnum"

export interface SvgIconProps {
    className?: string
    icon: IconsEnum
    fill?: string
}

export function SvgIcon(props: SvgIconProps) {
    const { className, icon, fill } = props

    return (
        <svg fill={fill} className={className}>
            <use xlinkHref={`#${icon}`}></use>
        </svg>
    )
}