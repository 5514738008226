import { faStop, faDesktop } from "@fortawesome/free-solid-svg-icons";
import { ControlsProps, useParticipant, AudioSelectButton, VideoSelectButton, ControlButton } from "@livekit/react-components";
import React, { ReactElement, } from "react";
import { RecorderPanel } from "./RecorderPanel";

export const ControlsRenderer = (({
    room,
    enableScreenShare,
    enableAudio,
    enableVideo,
    onLeave,
}: ControlsProps) => {
    const { cameraPublication: camPub } = useParticipant(room.localParticipant);

    if (enableScreenShare === undefined) {
        enableScreenShare = true;
    }
    if (enableVideo === undefined) {
        enableVideo = true;
    }
    if (enableAudio === undefined) {
        enableAudio = true;
    }

    const [audioButtonDisabled, setAudioButtonDisabled] = React.useState(false);
    let muteButton: ReactElement | undefined;
    if (enableAudio) {
        const enabled = room.localParticipant.isMicrophoneEnabled;
        muteButton = (
            <AudioSelectButton
                isMuted={!enabled}
                isButtonDisabled={audioButtonDisabled}
                onClick={async () => {
                    setAudioButtonDisabled(true);
                    room.localParticipant
                        .setMicrophoneEnabled(!enabled)
                        .finally(() => setAudioButtonDisabled(false));
                }}
                onSourceSelected={(device) => {
                    setAudioButtonDisabled(true);
                    room
                        .switchActiveDevice("audioinput", device.deviceId)
                        .finally(() => setAudioButtonDisabled(false));
                }}
            />
        );
    }

    const [videoButtonDisabled, setVideoButtonDisabled] = React.useState(false);

    let videoButton: ReactElement | undefined;
    if (enableVideo) {
        const enabled = !(camPub?.isMuted ?? true);
        videoButton = (
            <VideoSelectButton
                isEnabled={enabled}
                isButtonDisabled={videoButtonDisabled}
                onClick={() => {
                    setVideoButtonDisabled(true);
                    room.localParticipant
                        .setCameraEnabled(!enabled)
                        .finally(() => setVideoButtonDisabled(false));
                }}
                onSourceSelected={(device) => {
                    setVideoButtonDisabled(true);
                    room
                        .switchActiveDevice("videoinput", device.deviceId)
                        .finally(() => setVideoButtonDisabled(false));
                }}
            />
        );
    }

    const [screenButtonDisabled, setScreenButtonDisabled] = React.useState(false);
    let screenButton: ReactElement | undefined;
    if (enableScreenShare) {
        const enabled = room.localParticipant.isScreenShareEnabled;
        screenButton = (
            <ControlButton
                label={enabled ? "Stop sharing" : "Share screen"}
                icon={enabled ? faStop : faDesktop}
                disabled={screenButtonDisabled}
                onClick={() => {
                    setScreenButtonDisabled(true);
                    room.localParticipant
                        .setScreenShareEnabled(!enabled)
                        .finally(() => setScreenButtonDisabled(false));
                }}
            />
        );
    }

    return (
        <div >
            {muteButton}
            {videoButton}
            {screenButton}
            {onLeave && (
                <ControlButton
                    label="End"
                    // className={styles.dangerButton}
                    onClick={() => {
                        room.disconnect();
                        onLeave(room);
                    }}
                />
            )}

        </div>
    );
})