import '@livekit/react-components/dist/index.css';
import { BrowserRouter as Router, RouterProvider, createBrowserRouter, } from 'react-router-dom';
import { ShowOneParticipant } from './pages/ShowOneParticipant/ShowOneParticipant';
import { PreJoinPage } from './pages/PreJoinPage/PreJoinPage';
import { RoomPage } from './pages/RoomPage/RoomPage';
import { SvgSprite } from './svg/SvgSprite';
import { useLoadCssOnce } from './hooks/useLoadCssOnce';
import { Controller } from './external-controller/Controller';
import { RELATIVE_PATH$ } from './globals';

const paths: {
  [x in string]: { path: RegExp, element: React.FC, classicRouting?: boolean }
} = {
  participant: { path: /(.*\/)participant/, element:ShowOneParticipant },
  room: { path: /(.*\/)room/, element: RoomPage, classicRouting: true },
  join: { path: /(.*)/, element: PreJoinPage, classicRouting: true },
}


const substract_prefix = (regex: RegExp, path: string): string | undefined => {
  const match = path.match(regex);
  if (match) {
    const prefix = match[1];
    return prefix
  }
  return;
}

const App = (props: { controller: Controller }) => {
  const { controller } = props;
  const query = new URLSearchParams(window.location.search);
  useLoadCssOnce(query.get("css") || '')
  if (controller.useControllerRouter)
    return (
      <div className="container">
        <SvgSprite />
        <Router>

          <controller.Router />
      </Router>

      </div>
        )
  const path = window.location.pathname
  const PAGE = Object.values(paths).find(v => v.path.test(path))


  const PAGE_COMPONENT = PAGE?.element;
  if (!PAGE_COMPONENT) {
    return <>NOT FOUND</>
  }

  if (PAGE?.classicRouting) {
    const router = createBrowserRouter([
      {
        path: "/",
        element: <PreJoinPage></PreJoinPage>,
      }, {
        path: "room",
        element: <RoomPage></RoomPage>,
      },
    ]);
    return <RouterProvider router={router} />
  }

  /** Вычисляем отностительный путь */
  const new_relative = substract_prefix(PAGE?.path, path);
  if (new_relative && new_relative !== "/") {
    RELATIVE_PATH$.next(new_relative);
  }

  return (
    <div className="container">
      <SvgSprite />
      <Router>

        <PAGE_COMPONENT />
      </Router>

    </div>
  );
};

export default App;
