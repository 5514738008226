import styled from "@emotion/styled";

export const Absolute = styled.div < { index: number, left: number, right: number, bottom: number, top: number }>`
   display: flex;
   position: absolute;
    left:${p => (p.left)};
    right:${p => (p.right)};
    bottom:${p => (p.bottom)};
    top: ${p => (p.top)};

   z-index: ${p => (p.index)};
`;