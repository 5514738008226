import { BehaviorSubject, interval, map, tap } from "rxjs";

export class ScreenRecorder {
    private screenStream!: MediaStream;
    private recorder!: MediaRecorder;
    private chunks: Blob[] = []
    private _audioContext: AudioContext;
    private _audioContextDest: MediaStreamAudioDestinationNode;
    private _state$: BehaviorSubject<RecordingState | null> = new BehaviorSubject(null);
    get isDownloadAvaible() {
        return !!this.chunks.length;
    }

    get isRecording() {
        return this?.recorder && this.recorder.state === "recording"
    }

    get isRecording$() {
        return this._state$.pipe(map((state) => state === "recording"));
    }
    get isDownloadAvaible$() {
        return interval(1000).pipe(tap(() => console.log(this.chunks)), map(() => !!this.chunks?.length));
    }

    constructor(private readonly recordName = (new Date().toLocaleString())) { }

    private createAudioStream() {
        const audioTracks: MediaStreamTrack[] = Array.from(document.querySelectorAll('audio')).map(audio => (audio.srcObject as MediaStream).getAudioTracks()[0]);

        // get audio track from video element
        const video = document.querySelector('video');
        if (video && video.srcObject) {
            const audioTrack = (video.srcObject as MediaStream).getAudioTracks()[0];
            audioTracks.push(audioTrack);
        }

        const audioStream = new MediaStream();
        audioTracks.forEach(track => {
            track && audioStream.addTrack(track)
        });
        return audioStream;
    }

    async startRecording(tracks?: MediaStreamTrack[], constraits: { video?: boolean, audio?: boolean } = {
        video: true,
        audio: true,
    }) {
        this.chunks = [];

        if (constraits.video) {
        this.screenStream = await navigator.mediaDevices.getDisplayMedia({
            // @ts-ignore
            video: { mediaSource: "screen" },
        });
        }
        const localaudio = await navigator.mediaDevices.getUserMedia({ audio: true })

        this._audioContext = new AudioContext();

        const sources: MediaStreamAudioSourceNode[] = [];

        if (constraits.audio) {
        sources.push(this._audioContext.createMediaStreamSource(localaudio))
        tracks?.map((track) => {
            const source = new MediaStream()
            source.addTrack(track)
            return this._audioContext.createMediaStreamSource(source);
        }).forEach(track => {
            sources.push(track);
        })
        }


        const dest = this._audioContext.createMediaStreamDestination();

        this._audioContextDest = dest;

        sources.map(source => source.connect(dest))

        const stream = dest.stream;
        if (constraits.video) {

            this.screenStream.getVideoTracks().forEach(v => {
                stream.addTrack(v);
            })
        }

        // add all tracks from the second stream to the new stream



        this.recorder = new MediaRecorder(dest.stream,
            // {
            //     mimeType: `video/webm;codecs=avc1`,
            // }
        );

        this.recorder.ondataavailable = e => { this.chunks.push(e.data); this._state$.next(this.recorder.state) };

        this.recorder.start();

        this.recorder.onerror = (() => this._state$.next(this.recorder.state))

        this.recorder.onstart = () => { this._state$.next(this.recorder.state) }
        this.recorder.onstop = () => { this._state$.next(this.recorder.state) }
        this.recorder.onpause = () => { this._state$.next(this.recorder.state) }
        this.recorder.onresume = () => { this._state$.next(this.recorder.state) }
        this.recorder.onpause = () => { this._state$.next(this.recorder.state) }
    }

    async stopRecording() {
        this.recorder.stop();
        this.screenStream.getVideoTracks()[0].stop();
    }

    async addNewTracksToRecord(...tracks: MediaStreamTrack[]) {
        tracks?.map((track) => {
            if (track) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(track);
                try {
                    this._audioContext.createMediaStreamSource(mediaStream).connect(this._audioContextDest)

                } catch (e) {
                    console.log(e)
                }
                finally {
                    console.log("ura")
                }
            }
        })
    }

    async downloadLastVideo() {
        const downloadLink = document.createElement("a");
        const completeBlob = new Blob(this.chunks, { type: this.chunks[0].type });
        downloadLink.href = URL.createObjectURL(completeBlob);
        downloadLink.download = `${this.recordName}.webm`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

}